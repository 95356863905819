import React, { Component } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import './MessagingPlatformStats.css'

export default class MessagingPlatformStats extends Component {

    constructor(props) {
        super(props);

        var today = moment();
        var oneMonthAgo = moment().subtract(1, 'months');

        this.state = {
            startDate: oneMonthAgo.format('YYYY-MM-DD'),
            endDate: today.format('YYYY-MM-DD'),
        };
    }

    downloadCrowdologyStats = (event) => {
        this.props.server.getCrowdologyWorkbook();
    }

    downloadMyVueStats = (event) => {
        this.props.server.getMyVueWorkbook();
    }

    downloadCaffeNeroStats = (event) => {
        var start = moment(this.state.startDate);
        var end = moment(this.state.endDate);
        if (start.isBefore(end)) {
            this.props.server.getCaffeNeroWorkbook(this.state.startDate, this.state.endDate);
        } else {
            let toastStyle =
            {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: true,
                closeButton: false
            };

            toast.error("Start date must be before end date", toastStyle);
        }
    }

    onStartDateChanged = (event) => {
        this.setState({ startDate: event.target.value });
    }

    onEndDateChanged = (event) => {
        this.setState({ endDate: event.target.value });
    }

    render () {
        const {startDate, endDate} = this.state;

        return (
            <div>
                <h1>Messaging Platform Stats</h1>
                <div className='form-wrapper' >
                    <div className="flex-container">
                        <label>Crowdology stats:</label>
                        <div className='button-wrapper'>
                            <input className='btn' type='button' value="Download" onClick={this.downloadCrowdologyStats}/>
                        </div>
                    </div>
                </div>

                <div className='form-wrapper' >
                    <div className="flex-container">
                        <label>MyVue stats:</label>
                        <div className='button-wrapper'>
                            <input className='btn' type='button' value="Download" onClick={this.downloadMyVueStats} />
                        </div>
                    </div>
                </div>

                <div className='form-wrapper' >
                    <div className="flex-container">
                        <label>Caffe Nero stats:</label>
                        <div className='button-wrapper'>
                            <input className='btn' type='button' value="Download" onClick={this.downloadCaffeNeroStats} />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="date-container left">
                            <label htmlFor="startDateInput">Start date</label>
                            <input id="startDateInput" type="date" value={startDate} onChange={this.onStartDateChanged}/>
                        </div>
                        <div className="date-container right">
                            <label htmlFor="endDateInput">End date</label>
                            <input id="endDateInput" type="date" value={endDate} onChange={this.onEndDateChanged}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}