import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './HeaderNavBar.css'
import SavantaLogo from './images/savanta-logo.svg'

class HeaderNavBar extends Component {
    logOutClicked = (e) => {
        e.preventDefault();

        this.props.server.logOut();
        this.setState(this.state);
    }

    render() {
        return (<div className="header-full">
            <div className="header">
                <a href="https://fieldvue.savanta.com/">
                    <img src={SavantaLogo} className="header-img" alt="Savanta" />
                </a>
                <div className="nav-links">
                    <NavLink to={'/Unsubscribe'} activeClassName="selected" className="header-links">Unsubscribe</NavLink>
                    <NavLink to={'/Addresses'}  activeClassName="selected" className="header-links">Addresses</NavLink>
                    <NavLink to={'/MessagingPlatformStats'} activeClassName="selected" className="header-links">Stats</NavLink>
                    <NavLink to={'/EmailValidation'} activeClassName="selected" className="header-links">Emails</NavLink>
                    <a href="logout" className="header-links right" onClick={this.logOutClicked}>Log out</a>
                </div>
            </div>
        </div>)
    }
}

export default HeaderNavBar;