import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Dropzone, { FileRejection } from 'react-dropzone';

import './EmailValidation.css'

export default class EmailValidation extends Component {

    constructor(props) {
        super(props);

    }

    downloadOutStandingRespondents = (event) => {
        this.props.server.getRespondentsNotEmailValidated();
    }

    uploadFile = (file) => {

        const formData = new FormData();
        formData.append('file', file);
        this.props.server.uploadFile(formData);
    };


    onFilesDragDropped = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error(`Invalid file type for this operation. File must be .csv`, { position: toast.POSITION.BOTTOM_CENTER, autoClose: false, closeButton: true });
        } else {
            this.uploadFile(acceptedFiles[0]);
        }
    };   

    render () {

        return (
            <div>
                <h1>Email Validation</h1>
                <div className='form-wrapper' >
                    <div className="flex-container">
                        <label>Emails not yet validated</label>
                        <div className='button-wrapper'>
                            <input className='btn' type='button' value="Download" onClick={this.downloadOutStandingRespondents}/>
                        </div>
                    </div>
                </div>
                <div className='form-wrapper' >
                    <div className="flex-container">
                        <label>Upload validated list</label>
                        <div className='button-wrapper'>
                <Dropzone
                    noClick={true}            
                    onDrop={this.onFilesDragDropped}
                    multiple={false}
                    accept={['.csv']}>
                    {({ getRootProps, isDragActive }) => <div className={'drag-drop-area ' + (isDragActive ? 'drag-drop-active' : '')} {...getRootProps()}>
                        <div>drop a file here</div>
                    </div>}
                </Dropzone>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}